import React from 'react'
import PropTypes from 'prop-types'

import Logo from '../common/Logo'
import { Link } from 'gatsby'
import Text from '../common/Text'
import Lines from '../../assets/menu-lines.svg'
import SocialLinks from '../common/SocialLinks'

import styled from 'styled-components'
import { useTrail, useSpring, animated } from 'react-spring'
import { modularScale } from 'polished'

const config = { mass: 2, tension: 1500, friction: 120 }

const Menu = ({ list, hideModal, socialLinks }) => {
  const trail = useTrail(list.length, {
    config,
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: 40 },
    delay: 100,
  })

  const { y, ...styles } = useSpring({
    config,
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 40 },
    delay: 400,
  })
  return (
    <Container onClick={hideModal}>
      <Content>
        <LogoContainer>
          <Logo color="#F37268" />
        </LogoContainer>
        <ul>
          {!!list &&
            trail.map(({ x, height, ...rest }, index) => {
              const { id, title, slug } = list[index]
              return (
                <animated.li
                  key={id}
                  style={{
                    ...rest,
                    transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
                  }}
                >
                  <Link to={`/#${slug}`} onClick={hideModal}>
                    <LinkText spacing={1.88} semiBold white>
                      {title}
                    </LinkText>
                  </Link>
                </animated.li>
              )
            })}
        </ul>
        <animated.div
          style={{
            ...styles,
            transform: y.interpolate(y => `translate3d(0,${y}px,0)`),
          }}
        >
          <SocialLinks list={socialLinks} white />
        </animated.div>
      </Content>
      <BackgroundLines src={Lines} alt="Line" />
    </Container>
  )
}

Menu.propTypes = {
  list: PropTypes.array,
  socialLinks: PropTypes.array,
  toggle: PropTypes.func,
}

export default Menu

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 93vh;
  z-index: 1;
  pointer-events: all;

  a {
    cursor: pointer;
  }
`

const LinkText = styled(Text)`
  font-size: 6vw;
  line-height: 1.8;
`

const Content = styled.div`
  position: fixed;
  width: 100%;
  padding: 0 30px;

  ul {
    margin-bottom: ${modularScale(2)};
  }
`

const LogoContainer = styled.div`
  transform: translate(0, -32px);
  padding-bottom: 16px;
  border-bottom: 1px solid #fff;

  svg {
    width: 100%;
    height: auto;
    min-width: 20px;
    max-width: 56px;
    object-fit: contain;
  }
`

const BackgroundLines = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  max-height: 35vh;
  object-fit: cover;
  object-position: top;
  overflow: hidden;
`
