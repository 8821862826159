import React from 'react'

import styled, { css } from 'styled-components'
import mq from '../../style/mediaQueries'

const MenuButton = ({ hide, ...props }) => (
  <Button hide={hide}>
    <svg width={24} height={17} {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth={2}
        d="M22.645 1.063H1.128M22.645 8.5H1.128m21.517 7.438H1.128"
      />
    </svg>
  </Button>
)

export default MenuButton

const Button = styled.button`
  ${({ hide, theme }) => css`
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
    cursor: pointer;
    color: ${theme.color.blue};
    z-index: 9;
    opacity: ${hide ? 0 : 1};
    transition: opacity 0.3s, color 0.5s ease-in;

    &:hover {
      color: ${theme.color.coral};
      transition: color 0.5s ease-out;
    }

    ${mq.md`
        display: none;
    `};
  `};
`
