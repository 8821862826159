import { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class Portal extends Component {
  static propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.el = props.id
      ? document.getElementById(props.id)
      : document.createElement('div')
  }

  componentDidMount() {
    if (!this.props.id) document.body.appendChild(this.el)
  }

  componentWillUnmount() {
    if (!this.props.id) document.body.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default Portal
