import React from 'react'

const CloseIcon = props => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 24 24"
    fill="none"
    stroke="#fff"
    strokeWidth={2}
    strokeLinejoin="bevel"
    {...props}
  >
    <path d="M18 6L6 18M6 6l12 12" />
  </svg>
)

export default CloseIcon
