import React from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from './ErrorBoundary'

import { useSpring, animated, config } from 'react-spring'
import styled, { css } from 'styled-components'
import mq from '../../style/mediaQueries'
import { modularScale } from 'polished'

const SocialLinks = ({
  activeSection,
  list,
  small,
  large,
  style,
  white,
  vertical,
}) => {
  const hide = vertical && activeSection === 'social'

  const styles = vertical
    ? useSpring({
        transform: 'translateY(0px)',
        from: { opacity: 0, transform: 'translateY(15px)' },
        opacity: 1,
        config: config.slow,
        delay: activeSection === 'social' ? 0 : 1500,
      })
    : style

  return (
    <ErrorBoundary labe="SocialLinks">
      <List style={styles} large={large} vertical={vertical}>
        {!!list &&
          list.map(({ title, image, imageWhite, url }, i) => {
            const src = white ? imageWhite.file.url : image.file.url
            return (
              <SocialItem
                key={i}
                small={small}
                large={large}
                vertical={vertical}
                hide={hide}
              >
                <a href={url} target="_blank">
                  <img src={src} alt={title} />
                </a>
              </SocialItem>
            )
          })}
      </List>
    </ErrorBoundary>
  )
}

SocialLinks.propTypes = {
  large: PropTypes.bool,
  list: PropTypes.array,
  small: PropTypes.bool,
  style: PropTypes.object,
}

export default SocialLinks

const List = styled(animated.ul)`
  ${({ large, vertical }) => css`
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    svg path {
      transform: none;
    }

    ${large &&
      css`
        margin-top: ${modularScale(0)};
        transform: translateX(-12px);
      `};

    ${vertical &&
      css`
        display: none;
        position: fixed;
        left: 25px;
        bottom: 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30px;

        ${mq.md`
          display: flex;
        `};
      `};
  `};
`

const SocialItem = styled.li`
  ${({ hide, small, large, theme, vertical }) => css`
    ${theme.mixin.hover};
    padding: 0.3em;
    margin: 0 0.3em;
    transition: opacity 0.5s ease-out;

    img {
      height: 100%;
      width: auto;
      min-width: ${small ? 14 : 18}px;
      max-height: ${small ? 14 : 18}px;
      object-fit: contain;

      ${large &&
        css`
          min-width: 47px;
          max-height: 47px;
        `};
    }

    ${hide &&
      css`
        pointer-events: none;
        opacity: 0;
      `};

    ${large &&
      css`
        padding-left: 0;
      `};

    ${vertical
      ? css`
          margin: 0.3em 0;

          img {
            height: auto;
            width: 100%;
          }
        `
      : css`
          &:first-child {
            padding-left: 0;
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
            padding-right: 0;
          }
        `};
  `};
`
