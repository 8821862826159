import { em, stripUnit, modularScale } from 'polished'
import { css, keyframes } from 'styled-components'
import { mapValues, get } from 'lodash'
import Bowser from 'bowser'

export let device = { mobile: false }
if (typeof window !== 'undefined') {
  device = Bowser.getParser(window.navigator.userAgent)
  const type = get(device, 'parsedResult.platform.type')
  device.mobile = type === 'mobile' || type === 'tablet'
}

// px
export const breakpointsPx = {
  xs: 0, // from 0 to 575
  sm: 321, // from 576 to 767
  md: 577, // from 576 to 767
  lg: 769, // from 768 to 991
  xl: 993, // from 992 to 1199
  xxl: 1200, // from 1200 to endless
}

// em
export const breakpoints = mapValues(breakpointsPx, value =>
  stripUnit(em(value))
)

export const color = {
  base: '#121212', // Black
  coral: '#F37268',
  darkRed: '#D73B3B',
  secondary: '#e9e9e9', // Medium Gray
  tertiary: '#f3f3f3', // Light Gray
  highlight: '#f37268', // Coral
  blue: '#3E4680',
  blueDark: '#00263E',
  blueLight: '#CEDDE9',
  blueLight2: '#D3E9F2',
  grey: '#F6F7F9', // About bg
}

// rem
export const fontSizes = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 30,
  xl: 49,
}

const verticalMargin = css`
  padding-top: ${modularScale(6)};
  padding-bottom: ${modularScale(6)};

  @media (min-width: ${breakpoints.lg}em) {
    padding-top: ${modularScale(8)};
    padding-bottom: ${modularScale(8)};
  }
`

const layout = {
  padding: 80,
  paddingSm: 60,
  maxWidth: 1260,
  maxWidthPx: '1260px',
  maxWidthCentered: '800px',
  verticalMargin,
}

const hover = css`
  transition: opacity 0.4s ease-in;
  cursor: pointer;

  &:hover {
    transition: opacity 0.3s ease-out;
    opacity: 0.6;
  }
`

const fadeInFrames = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        animation-delay: 0;
    }
`

const fadeIn = (time = 0.7) => css`
  animation: ${fadeInFrames} ${time}s ease-out;
`

const button = css`
  background-color: ${color.coral};
  border-radius: 100px;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.78px;
  text-align: center;
  min-height: 40px;
  padding: 0 ${modularScale(3)};
  max-width: 180px;
  transition: background-color 0.4s ease-in;
  cursor: pointer;

  &:hover {
    transition: background-color 0.3s ease-out;
    background-color: #f0877f;
  }
`

const theme = {
  breakpoints,
  color,
  device,
  fontSizes,
  layout,
  mixin: {
    button,
    fadeIn,
    hover,
  },
  flexa: {
    columns: 12,
    gutter: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0,
      xxl: 0,
    },
    breakpoints,
  },
}

export default theme
