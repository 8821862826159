import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'gatsby'
import Logo from '../../assets/logo.svg'
import MenuButton from './MenuButton'
import Modal from '../common/Modal'
import Text from '../common/Text'

import styled, { css } from 'styled-components'
import mq from '../../style/mediaQueries'
import { useMedia } from 'use-media'
import { breakpointsPx } from '../../style/theme'

const TOP = 'top'
const MIN_HEIGHT = 54
const MAX_HEIGHT = 60

const Nav = ({ navLinks, socialLinks }) => {
  const isWide = useMedia({ minWidth: breakpointsPx.md })
  const [show, showModal] = useState(false)
  const toggle = show ? () => showModal(false) : () => showModal(true)

  useEffect(() => {
    if (isWide && show) toggle()
  })

  return (
    <>
      <Spacer id={TOP} />
      <Header>
        <nav>
          <Link to="/">
            <Img src={Logo} alt="Jino" />
          </Link>
          <ul>
            {!!navLinks &&
              navLinks.map(({ id, title, slug }) => (
                <li key={id}>
                  <Link to={`/#${slug}`} activeStyle={activeLinkStyle}>
                    <Text spacing={1.44}>
                      <b>{title}</b>
                    </Text>
                  </Link>
                </li>
              ))}
          </ul>
          <MenuButton onClick={() => showModal(!show)} hide={show} />
        </nav>
        <Modal
          fullscreen
          isMenu
          show={show}
          hideModal={() => showModal(false)}
          onClose={() => showModal(false)}
          list={navLinks}
          socialLinks={socialLinks}
        />
      </Header>
    </>
  )
}

Nav.propTypes = {
  navLinks: PropTypes.array,
}

export default Nav

const height = css`
  height: ${MIN_HEIGHT}px;

  ${mq.lg`
      height: ${MAX_HEIGHT}px;
    `};
`

const Spacer = styled.div`
  ${height};
  background-color: rgba(255, 255, 255, 1);
`

const Header = styled.header`
  ${height};
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.95);

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: calc(100vw - 60px);
    margin-left: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.color.blueLight};
  }

  ul {
    display: none;

    justify-content: space-between;

    ${mq.md`
      display: flex;
    `};
  }

  li {
    display: inline-block;
    margin-left: 1em;

    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    &:hover {
      color: black;
    }
  }
`

export const Img = styled.img`
  max-height: 17px;
  width: auto;

  ${mq.md`
    max-height: 26px;
  `};

  ${mq.xl`
    max-height: 30px;
  `};

  ${mq.xl`
    max-height: 32px;
  `};

  ${mq.xxl`
    max-height: 36px;
  `};
`

const activeLinkStyle = {
  color: 'white',
}
