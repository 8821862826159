import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '../../assets/CloseIcon'
import ModalBox from './ModalBox'
import Portal from './Portal'
import Menu from '../menu/Menu'

import keyCodes from '../../constants/keyCodes'
import styled, { css, keyframes } from 'styled-components'
import { timingFunctions } from 'polished'
import { isNull } from 'lodash'

class Modal extends Component {
  static propTypes = {
    actions: PropTypes.object,
    children: PropTypes.node,
    fullscreen: PropTypes.bool,
    onClose: PropTypes.func,
    onKeyDown: PropTypes.func,
    hideModal: PropTypes.func.isRequired,
    noScroll: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    small: PropTypes.bool,
    targetId: PropTypes.string,
  }

  state = {
    queuedToHide: false,
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props
    if (!prevProps.show && show) this._addListeners(show)
  }

  componentWillUnmount() {
    this._addListeners(false)
    this._reset()
  }

  close = () => this._handleClose()

  _addListeners = (add = true) => {
    if (typeof window !== 'undefined') {
      if (add) window.addEventListener('keydown', this._onKeyDown, false)
      else window.removeEventListener('keydown', this._onKeyDown)
    }
  }

  _onKeyDown = e => {
    const { onKeyDown } = this.props
    const key = e.which || e.keyCode
    if (key === keyCodes.ESC) this._handleClose()
    if (onKeyDown) onKeyDown(key)
  }

  _handleClose = () => {
    const { hideModal, show } = this.props
    if (show) {
      this._reset(true)
      if (hideModal) hideModal()

      this._timeout = setTimeout(this._reset, TIME - 10)
    }
  }

  _reset = (queuedToHide = false) => {
    const { onClose } = this.props
    this.setState({ queuedToHide })

    if (!isNull(this._timeout)) {
      clearTimeout(this._timeout)
      this._timeout = null
      if (!queuedToHide && onClose) onClose()
    }
  }

  render() {
    const {
      children,
      fullscreen,
      list,
      noScroll,
      show,
      small,
      socialLinks,
      targetId,
    } = this.props
    const { queuedToHide } = this.state
    const showAll = show || queuedToHide

    return showAll ? (
      <Portal id={targetId}>
        <Container hide={!show.toString()}>
          {!fullscreen && <Bg onClick={this._handleClose} />}
          <ModalBox show={show} fullscreen={fullscreen} small={small}>
            <Card>
              <Top>
                <CloseButton onClick={this._handleClose}>
                  <CloseIcon />
                </CloseButton>
              </Top>
              <Content noScroll={noScroll} hide={!show}>
                <Menu
                  list={list}
                  socialLinks={socialLinks}
                  hideModal={this._handleClose}
                />
                {children}
              </Content>
            </Card>
          </ModalBox>
        </Container>
      </Portal>
    ) : null
  }
}

const TIME = 400

export default Modal

export const fadeIn = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        animation-delay: 0;
    }
`

export const fadeInWithDelay = keyframes`
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        animation-delay: 0;
    }
`

export const fadeAndScaleIn = keyframes`
    0% {
        height: 0;
    }

    100% {
        height: 100%;
        animation-delay: 0;
    }
`

export const fadeOutWithDelay = keyframes`
    0% {
        opacity: 1;
    }

     50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        animation-delay: 0;
    }
`

const transitionIn = css`
  animation: ${fadeIn} ${TIME / 1000}s ${timingFunctions('easeOutQuad')};
`

const transitionOut = css`
  pointer-events: none;
  opacity: 0;
  transition: opacity ${TIME / 1000}s ${timingFunctions('easeInQuad')};
`

const Card = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ hide }) => (hide ? transitionOut : transitionIn)};
  z-index: 99999999999999;
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  ${transitionIn};
`

const PAD_V = 50

const Content = styled.div`
  position: relative;
  height: calc(50% - ${PAD_V}px);
  max-height: calc(100vh - 120px);
  max-width: calc(100vw - 120px);

  ${({ fullscreen, noScroll }) => css`
    margin-top: ${PAD_V}px;
    height: calc(100vh - ${PAD_V}px);
    max-height: calc(100vh - ${PAD_V}px);
    max-width: 100%;

    ${!noScroll &&
      css`
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      `};
  `};
`

const Top = styled.div`
  position: absolute;
  top: 0;
  padding: 8px 0;
  right: 30px;
  z-index: 10;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 2px 5px;
  cursor: pointer;
  transform: translateX(8px);
  transition: opacity 0.3s ease-in;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.3s ease-out;
  }
`

export const ModalPad = styled.div`
  position: relative;
`
