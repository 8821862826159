import { css } from 'styled-components'

import Book1 from '../assets/fonts/SharpSansNo1-Book.woff2'
import Book2 from '../assets/fonts/SharpSansNo1-Book.woff'
import Book3 from '../assets/fonts/SharpSansNo1-Book.ttf'
import Bold1 from '../assets/fonts/SharpSansNo1-Bold.woff2'
import Bold2 from '../assets/fonts/SharpSansNo1-Bold.woff'
import Bold3 from '../assets/fonts/SharpSansNo1-Bold.ttf'
import Medium1 from '../assets/fonts/SharpSansNo1-Medium.woff2'
import Medium2 from '../assets/fonts/SharpSansNo1-Medium.woff'
import Medium3 from '../assets/fonts/SharpSansNo1-Medium.ttf'
import Semibold1 from '../assets/fonts/SharpSansNo1-Semibold.woff2'
import Semibold2 from '../assets/fonts/SharpSansNo1-Semibold.woff'
import Semibold3 from '../assets/fonts/SharpSansNo1-Semibold.ttf'
// import Light1 from '../assets/fonts/SharpSansNo1-Light.woff2'
// import Light2 from '../assets/fonts/SharpSansNo1-Light.woff'
// import Light3 from '../assets/fonts/SharpSansNo1-Light.ttf'

export const fonts = css`
  @font-face {
    font-family: 'SharpSansNo1';
    src: url(${Book1}) format('woff2'), url(${Book2}) format('woff'),
      url(${Book3}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'SharpSansNo1';
    src: url(${Bold1}) format('woff2'), url(${Bold2}) format('woff'),
      url(${Bold3}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'SharpSansNo1';
    src: url(${Medium1}) format('woff2'), url(${Medium2}) format('woff'),
      url(${Medium3}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  ${'' /* @font-face {
    font-family: 'SharpSansNo1';
    src: url(${Light1}) format('woff2'), url(${Light2}) format('woff'),
      url(${Light3}) format('truetype');
    font-weight: 300;
    font-style: normal;
  } */}

  @font-face {
    font-family: 'SharpSansNo1';
    src: url(${Semibold1}) format('woff2'), url(${Semibold2}) format('woff'),
      url(${Semibold3}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }
`
