import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { useSpring, animated } from 'react-spring'

const config = { mass: 1, tension: 120, friction: 20 }

const ModalBox = ({ children, fullscreen, show, small }) => {
  const styles = useSpring({
    reverse: !show,
    from: {
      opacity: 0,
      height: '0%',
    },
    height: '100%',
    opacity: 1,
    config: config.gentle,
  })

  return (
    <Box fullscreen={fullscreen} small={small} style={styles}>
      {children}
    </Box>
  )
}

ModalBox.propTypes = {
  children: PropTypes.node,
  fullscreen: PropTypes.bool,
  show: PropTypes.bool,
  small: PropTypes.bool,
}

export default ModalBox

const Box = styled(animated.div)`
  ${({ fullscreen, small }) => css`
    position: relative;
    background-color: ${({ theme }) => theme.color.coral};
    overflow: hidden;

    ${fullscreen
      ? css`
          top: 0;
          left: 0;
          width: 100%;
        `
      : css`
          width: calc(100% - 40px);
          max-width: 800px;
          border-style: solid;
          border-width: 2px;
          border-color: rgb(217, 217, 217);
          box-shadow: 4px 4px 10px 10px rgba(0, 0, 0, 0.1);
        `};

    ${!fullscreen &&
      small &&
      css`
        max-width: 500px;
      `};
  `};
`
