import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

import ErrorBoundary from './common/ErrorBoundary'

import Helmet from 'react-helmet'
import GlobalStyle from '../style/global'
import Loader from './common/Loader'
import Nav from './nav/Nav'
//import Footer from './footer/Footer'
import { ThemeProvider } from 'styled-components'
import { ParallaxProvider } from 'react-scroll-parallax'
import SEO from './SEO'

import MqDebug from '../utils/MqDebug'

import config from '../utils/siteConfig'
import theme from '../style/theme'
import styled from 'styled-components'

import favicon from '../images/favicon.ico'

const loadIO = async () => await require('intersection-observer')
let loadedIO = false

const Layout = ({
  children,
  keywords,
  metaDescription,
  navLinks,
  siteTitle,
  socialLinks,
}) => {
  const needsIO =
    typeof window !== 'undefined' &&
    typeof window.IntersectionObserver === 'undefined'
  const [show, setShow] = useState(needsIO ? false : true)

  useEffect(() => {
    if (needsIO && !loadedIO) {
      loadIO()
      loadedIO = true
      setShow(true)
    }
  })

  const title = siteTitle || config.siteTitle

  return (
    <ParallaxProvider>
      <Root>
        <Helmet>
          <title>{title}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href={favicon} />
        </Helmet>

        <SEO
          keywords={keywords}
          metaDescription={metaDescription}
          siteTitle={title}
        />

        <ThemeProvider theme={theme}>
          <ErrorBoundary label="Layout">
            {show ? (
              <Content className="content" id="content">
                <ErrorBoundary label="Nav">
                  <Nav navLinks={navLinks} socialLinks={socialLinks} />
                </ErrorBoundary>
                <ErrorBoundary label="Content">{children}</ErrorBoundary>
              </Content>
            ) : (
              <Center>
                <Loader />
              </Center>
            )}
            {/* <Footer /> */}
          </ErrorBoundary>
        </ThemeProvider>
        <GlobalStyle />
        <MqDebug />
      </Root>
    </ParallaxProvider>
  )
}

Layout.propTypes = {
  navLinks: PropTypes.array,
  socialLinks: PropTypes.array,
}

export default Layout

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`
